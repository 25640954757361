<template>
    <!-- Error page-->
    <div class="misc-wrapper bg-fourthColor">
        <!-- Brand logo-->
        <b-link to="/" class="brand-logo">
            <img
                src="@/assets/duplioAsset/duplioAssets/golfigo3.png"
                width="100px"
                alt=""
            />
        </b-link>

        <div class="">
            <b-container>
                <b-row
                    align-h="center"
                    align-v="center"
                    class="position-relative"
                >
                    <b-col
                        cols="12"
                        md="6"
                        lg="8"
                        order-sm="2"
                        order-md="2"
                        order-lg="2"
                        class="d-flex justify-content-center"
                    >
                        <b-img class="image" :src="imgUrl" alt="Error page" />
                    </b-col>
                    <b-col
                        order-sm="1"
                        order-md="1"
                        order-lg="1"
                        cols="12"
                        md="6"
                        lg="4"
                        class="text-center text-lg-left"
                    >
                        <h1 style="font-size: 42px" class="mb-1">
                            {{ $t('Message.notFound') }}
                        </h1>
                        <p class="mb-2">
                            {{ $t('Message.requestNotFount') }}
                        </p>

                        <div
                            class="d-flex justify-content-center justify-content-lg-start"
                        >
                            <b-button
                                variant="secondaryColor"
                                class="mb-2"
                                :to="{path: '/'}"
                            >
                                {{ $t('Message.backToHome') }}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
    <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import store from '@/store/index';

export default {
    data() {
        return {
            downImg: require('@/assets/icon/404.png'),
        };
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.downImg = require('@/assets/images/pages/error-dark.svg');
                return this.downImg;
            }
            return this.downImg;
        },
    },
};
</script>

<style scoped>
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .image {
        height: 250px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .image {
        height: 250px;
    }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .image {
        height: 350px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .image {
        height: 550px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .image {
        height: 650px;
    }
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
